import { graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import abstractShapes1 from '../assets/svg/components/abstract-shapes-1.svg';
import SEO from '../components/Seo';

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        lang={i18n.language}
        description={post.frontmatter.description || post.excerpt}
        isBlogPost
      />

      <GatsbyImage
        image={getImage(post.frontmatter.image)}
        style={{ height: '30vw', minHeight: '180px', backgroundSize: '100% auto' }}
      />

      <section className="container space-top-3 space-bottom-2">
        <div className="w-lg-60 mx-lg-auto">
          <article itemScope itemType="http://schema.org/Article">
            <header>
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <div className="border-top border-bottom py-4 mb-5 mt-4">
                <div className="media align-items-center mt-auto">
                  <StaticImage
                    className="rounded-circle mr-3"
                    src="../assets/images/profile-pic.png"
                    alt="avatar"
                    layout="fixed"
                    width={43}
                    height={43}
                  />
                  <div className="media-body">
                    <span className="text-dark d-inline-block font-weight-bold">
                      {post.frontmatter.author}
                    </span>
                    <small className="d-block">
                      {post.frontmatter.date} ·{' '}
                      {Math.ceil(post.fields.readingTime.minutes.toFixed(2))} {t('blog.minRead')}
                    </small>
                  </div>
                </div>
              </div>
            </header>
            <section dangerouslySetInnerHTML={{ __html: post.html }} itemProp="articleBody" />
            <div
              className="card bg-img-hero bg-dark text-white text-center p-4 my-4"
              style={{
                backgroundImage: `url(${abstractShapes1})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
              }}
            >
              <h4 className="text-white mb-3">{t('blog.footerCta')}</h4>

              <div className="text-center">
                <button type="button" className="btn btn-light ml-3">
                  {t('label.getInTouch')}
                </button>
              </div>
            </div>
            <div className="mt-3">
              <button className="btn btn-xs btn-soft-secondary mr-1 mb-2" type="button">
                Ecommerce
              </button>
              <button className="btn btn-xs btn-soft-secondary mx-1 mb-2" type="button">
                Website
              </button>
              <button className="btn btn-xs btn-soft-secondary mx-1 mb-2" type="button">
                Bootstrap
              </button>
              <button className="btn btn-xs btn-soft-secondary mx-1 mb-2" type="button">
                Startup
              </button>
              <button className="btn btn-xs btn-soft-secondary mx-1 mb-2" type="button">
                Free
              </button>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        locale
        readingTime {
          minutes
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        image {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`;
